import AOS from "aos";
import React, { useEffect } from "react";
import "aos/dist/aos.css";

const datas = [
  {
    company: "Internship at SMP GIKI 3 Surabaya",
    description:
      "I have undertaken the development of a web profile utilizing PHP and created a system information application tailored for library activities, employing Java programming for optimal functionality and efficiency.",
    logo: "M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9",
  },
  {
    company: "HRD Payroll at PT. Siantar Madju",
    description:
      "I am responsible for overseeing data entry processes, generating monthly reports, calculating staff salaries, managing internal and external staff attendance records, and implementing continuous improvement initiatives for the overall enhancement of company operations.",
    logo: "M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3",
  },
  {
    company: "Coding Instructor at Kodekiddo",
    description:
      "I am dedicated to the pedagogical responsibilities of instructing coding and coordinating extracurricular activities within educational institutions. In addition, I carefully compile daily summaries for comprehensive reporting to parents. In addition, I am actively involved in structuring the curriculum, especially discussing the topic of Artificial Intelligence, to ensure a comprehensive and effective educational experience.",
    logo: "M13 10V3L4 14h7v7l9-11h-7z",
  },
  {
    company: "Comp Science Teacher at Sekolah Bogor Raya",
    status: "",
    description:
      "I am actively involved in a professional capacity in teaching Computer Science subjects, compiling the Cambridge Curriculum for Computer Science at secondary level, taking on the role of Homeroom teacher for secondary school students, supervising the operations of the Video Editing Club as its supervisor, and contribute to the Special Educational Needs (SEN) programme.",
    logo: "M12 14v8H4a8 8 0 0 1 8-8zm0-1c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm9.446 7.032l1.504 1.504-1.414 1.414-1.504-1.504a4 4 0 1 1 1.414-1.414zM18 20a2 2 0 1 0 0-4 2 2 0 0 0 0 4z",
  },
  {
    company: "Computing Lecturer at UIC College",
    status: "present",
    description:
      "I am currently teaching at UIC College, I teach several diploma Programs and IGCSE levels such as: \
UTS College (University of Technology Sydney) on subject Web Design and Database Fundamentals.\
BTEC Computing program on subject Database Design and Development, Database Management Systems, Networking, Web Design Development and IT Skills for Business.\
teaching high school ICT subjects at the IGCSE level using the Pearson Edexcel curriculum. \
Additionally, Uniprep programs for UNSW foundation in subject Computing for Business, Science, and Design. \
also help IT Coordinator to maintain IT Infrastructure.",
    logo: "m50,0 a50,50,0,0,0,0,100 a-50,-50,0,0,0,0,-100 z m-8,32 a8,8,0,0,1,-16,0 a8,8,0,0,1,16,0 z m32,0 a8,8,0,0,1,-16,0 a8,8,0,0,1,16,0 z m-54,30 h60 c-5,26,-55,26,-60,0",
  },
];

function Experience() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <div className="py-12 transition-all duration-1000 ease-in-out bg-white bg-cover dark:bg-black lg:dark:bg-blackpattern dark:border-t dark:border-neutral-500 " id="Experience ">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-lg font-semibold text-indigo-600">()</h2>
          <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl sm:tracking-tight dark:text-neutral-300">
            Experience
          </p>
          <p className="max-w-2xl mt-4 italic text-gray-500 text-l lg:mx-auto">
            "The only source of knowledge is Experience." ~ Albert einstein
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {datas.map((data) => (
              <div className="relative" data-aos="zoom-in">
                <dt>
                  <div className="absolute flex items-center justify-center w-12 h-12 text-white bg-indigo-500 rounded-md dark:hover:text-red-500 hover:text-red-500 dark:bg-white dark:bg-opacity-10 dark:border-blue-700 dark:text-blue-700">
                    <svg
                      className="w-6 h-6 transition-all duration-500 ease-in-out"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d={data.logo}
                      />
                    </svg>
                  </div>
                  <p className="ml-16 text-lg font-medium leading-6 text-gray-900 dark:text-neutral-300">
                    {data.company}{" "}
                    <span className="text-xs text-indigo-500">{data.status}</span>
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  {data.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}

export default Experience;
